.datascroller-demo .product-name {
    font-size: 1.5rem;
    font-weight: 700;
}

.datascroller-demo .product-description {
    margin: 0 0 1rem 0;
}

.datascroller-demo .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
}

.datascroller-demo .product-category {
    font-weight: 600;
    vertical-align: middle;
}

.datascroller-demo .product-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.datascroller-demo .product-item img {
    width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
}

.datascroller-demo .product-item .product-detail {
    flex: 1 1 0;
}

.datascroller-demo .product-item .p-rating {
    margin: 0 0 .5rem 0;
}

.datascroller-demo .product-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
}

.datascroller-demo .product-item .product-action {
    display: flex;
    flex-direction: column;
}

.datascroller-demo .product-item .p-button {
    margin-bottom: .5rem;
}

.media_info_label {
    top: 20px;
    right: 29px;
    text-transform: capitalize;
}

.media_options_container .active_upload_button {
    color: #4f46e5 !important;
    background-color: rgba(105, 108, 255, 0.16) !important;
    font-weight: bolder;
}

@media screen and (max-width: 576px) {
    .datascroller-demo .product-item {
        flex-direction: column;
        align-items: center;
    }

    .datascroller-demo .product-item img {
        width: 75%;
        margin: 2rem 0;
    }

    .datascroller-demo .product-item .product-detail {
        text-align: center;
    }

    .datascroller-demo .product-item .product-price {
        align-self: center;
    }

    .datascroller-demo .product-item .product-action {
        display: flex;
        flex-direction: column;
    }

    .datascroller-demo .product-item .product-action {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.btn-spinner {
    animation-name: button-spinner !important;
    stroke-dasharray: 89, 200 !important;
    stroke-dashoffset: 0 !important;
    stroke: white !important;
    animation: button-spinner 1.5s ease-in-out infinite, button-spinner 6s ease-in-out infinite !important;
    stroke-linecap: round !important;
}


.btn-spinner circle {
    animation-name: button-spinner !important;
    stroke-dasharray: 89, 200 !important;
    stroke-dashoffset: 0 !important;
    stroke: white !important;
    animation: button-spinner 1.5s ease-in-out infinite, button-spinner 6s ease-in-out infinite !important;
    stroke-linecap: round !important;
}

/* progress-spinner color */
@keyframes button-spinner {

    100%,
    0% {
        stroke: white;
    }

    40% {
        stroke: white;
    }

    66% {
        stroke: white;
    }

    80%,
    90% {
        stroke: white;
    }
}