.next_prayer *,
.prayer_countdown div h2 {
    color: black;
}

.next_prayer {
    background-color: #E7E7FF;
    flex-direction: column;
    text-align: center;

}

.prayer_countdown {
    color: #696CFF;
    text-align: center;
    font-weight: bold;
    flex-direction: column;
}

.stream_datatable .p-datatable .p-datatable-tbody>tr>td {
    padding: .6rem 1rem !important;
}

.stream_datatable .p-datatable .p-datatable-thead>tr>th {
    padding: .7rem 1rem !important;

}

.stream_datatable .p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 2rem !important;
    height: 2rem !important;
}

.stream_datatable .p-paginator .p-paginator-first,
.stream_datatable .p-paginator .p-paginator-prev,
.stream_datatable .p-paginator .p-paginator-next,
.stream_datatable .p-paginator .p-paginator-last {
    min-width: 2rem !important;
    height: 2rem !important;
}

.stream_datatable .table> :not(caption)>*>* {
    padding: 0.25rem 0.25rem !important;
}

.stream_datatable .p-paginator {
    padding: 0.5rem 1rem 0 1rem;
}

.feed_image {
    height: 100% !important;
}

.feed_container {
    min-height: 400px;
    max-height: 30vh;
}

.rss_settings_wrapper {
    max-height: 100%;
    overflow-y: auto;
}

.custom_select.p-buttonset .p-button {
    margin: 0;
    padding: 5px 3px !important;
    font-size: 14px;
    flex:1
}

.p-colorpicker-preview {
    width: 22px;
    height: 22px;
}

.bg_color_input {
    padding: 0 !important;
    border-radius: 0;
}

.bg_color_input:enabled:focus {
    box-shadow: 0px 1px 0 0px #6366F1;
    -webkit-box-shadow: 0px 1px 0 0px #6366F1;
    -moz-box-shadow: 0px 1px 0 0px #6366F1;
}

.color_picker {
    z-index: 3112;
  }

 .upload_separator .p-button-icon{
    display: none !important;

 }

 .rss_wrapper{
    max-height: "200px";
    overflow-y: "scroll";
 }

 .rss_cards_container{
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }