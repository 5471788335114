.schedule_campaign_container {
    max-height: 400px;
    overflow-y: auto;
}

.schedule_card .p-card-body .p-card-content {
    padding: 0;
}

.group_card {
    -webkit-border-radius: 10px !important;
    -webkit-border-top-left-radius: 0 !important;
    -moz-border-radius: 10px !important;
    -moz-border-radius-topleft: 0 !important;
    border-radius: 10px !important;
    border-top-left-radius: 0 !important;
}

.group_title {
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    text-transform: capitalize;
}

.playing_now_container{
    border: 1px solid transparent;
    transition: all .3s;
}

.playing_now_container:hover {
    border: 1px solid #718193;
    border-radius: 5px;
}

.device_actions .p-splitbutton-menubutton{
    width: auto !important;
    padding: 5px 10px !important;
    border-radius: 2px !important;

}