 @tailwind base;
 @tailwind components;
 @tailwind utilities;

 body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 img {
   max-width: inherit !important;
 }

 .fs-12 {
   font-size: 12px !important;
 }

 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }

 .playlist_card .p-card-body .p-card-content {
   padding: 0 !important;
 }

 .media_hight {
   height: 10vh;
 }

 .dashboard_img {
   height: 150px !important;
   display: flex;
 }

 .p-inputnumber-buttons-stacked .p-inputnumber-input {
   width: 70px !important;
 }

 .fa-trash-alt {
   color: #b4bdc6;
   transition: all .3s;
 }

 .fa-trash-alt:hover {
   color: red;
 }

 #media_setting_form {
   height: 80%
 }

 .image_container {
   width: 100%;
   height: 60px;

 }

 .p-checkbox .p-checkbox-box {
   border-width: 1px !important;
 }

 .fs-10 {
   font-size: 10px;
 }

 .w-90 {
   width: 92.7%;
 }

 .m_col_1 {
   margin-left: 8.33333333% !important;
 }

 #media_container {
   min-height: 500px;
 }

 .p-avatar-group {
   margin-bottom: 0 !important;
 }

 .p-avatar-image img {
   border-radius: 0.375rem !important;
   transition: all .3s;
 }

 .p-avatar-image img:hover {
   width: 65px;
   height: 65px;
   z-index: 55;
 }

 /* switch button */
 .p-inputswitch .p-inputswitch-slider:before {
   width: 1rem;
   height: 1rem;
   left: 0.25rem;
   margin-top: -0.53rem;
 }

 .p-inputswitch {
   width: 2.4rem;
   height: 1.35rem;
 }

 .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
   transform: translateX(0.9rem);
 }

 .fs-8 {
   font-size: 13px;
 }

 .w_20 {
   min-width: 20%;
 }

 /*dialog  close button  */
 .pi {
   font-size: .9rem;
 }

 .p-dialog .p-dialog-header .p-dialog-header-icon {
   width: 1.5rem;
   height: 1.5rem
 }

 /* calender input width */
 .p-calendar {
   width: 100% !important;
 }


 .dataview-demo .product-grid-item img {
   box-shadow: none !important;
 }

 .dataview-demo .product-list-item img {
   box-shadow: none !important;
 }

 .cursor-pointer:hover {
   color: #6366f1;
 }

 .media_container {
   box-shadow: 0 0 0.375rem 0.25rem rgb(161 172 184 / 15%)
 }

 .layout_media {
   height: 100px;
 }

 .btn_container {
   background-color: #f0f1f57a !important;
 }

 /* drag and drop */
 .draggable_container {
   border-radius: 10px;
   padding: 5px 10px;
   margin: 0 0 8px 0;
   border: 1px solid #f5f5f9;
   color: #566a7f;
 }

 .droppable_container {
   padding: 4;
   width: 100%;
   min-height: 300;
   border-radius: 4px;
 }

 .p-dialog .p-dialog-header {
   border-bottom: .5px solid #e2dfdf !important;
 }

 .p-dialog .p-dialog-footer {
   border-top: .5px solid rgb(226, 223, 223) !important;
   padding: 1.2rem !important
 }

 .p-dialog .p-dialog-content {
   padding: 0 !important;
 }

 .bx-trash {
   transition: all .3s;
 }

 .fa-pencil:hover,
 .pi-pencil:hover {
   color: rgb(80, 143, 80) !important;
 }

 .bx-trash:hover {
   color: rgba(255, 0, 0, 0.823) !important;
 }

 .duration_input input {
   width: 50%;
 }

 .p-password input {
   width: 100%;
 }

 .react-thumbnail-generator img {
   width: 100%;
 }

 .media_grid .react-thumbnail-generator img {
   height: 85px;
   margin: 4px auto;
 }

 .dragdrop_scroll {
   height: calc(100vh - 228px);
   overflow-y: auto;
   padding-top: -100px;
 }

 .screen .static {
   position: inherit;
 }

 .p-sidebar-header {
   padding: 5px !important;
 }

 .p-sidebar {
   position: relative;
 }

 .p-sidebar-header {
   position: absolute;
   right: 5px;
   top: 5px;
 }

 /* progress-spinner color */
 .p-progress-spinner-circle {
   stroke: #6366f1 !important
 }


 /* Scroll bar */
 .scroll_div::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px #F5F5F5;
   background-color: #F5F5F5;
   border-radius: 10px
 }

 .scroll_div::-webkit-scrollbar {
   width: 5px;
   background-color: #F5F5F5;
   border-radius: 10px
 }

 .scroll_div::-webkit-scrollbar-thumb {
   background-color: #767b815e;
   border: 1px solid #F5F5F5;
   border-radius: 10px
 }

 .p-tooltip {
   text-transform: capitalize !important;
   font-size: 13px !important;
   padding: 5px;

 }

 .p-progressbar{
  height: 5px !important;
 }
 @keyframes p-progress-spinner-color {

   100%,
   0% {
     stroke: #6366f1 !important;
   }

   40% {
     stroke: #6366f1 !important;
   }

   66% {
     stroke: #6366f1 !important;
   }

   80%,
   90% {
     stroke: #6366f1 !important;
   }
 }


 @media (max-width: 426px) {
   .image_container {
     width: 100%;
     height: 42px;
   }

   .playlist_icon_container {
     height: 40px;
   }
 }