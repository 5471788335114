.custom-dialog .p-dialog-header {
  display: none !important;
}

.bg_gray {
  background-color: #f6f7f7;
}

.radio_bg .p-radiobutton-box {
  background-color: #ced4da !important;
}

.close_icon {
  top: 20px;
  right: 25px;
  font-size: 20px;
}
.download_icon {
  transition: transform .2s; /* Animation */
}
.download_icon:hover {
  color: black !important;
  transform : scale3d(1.2) ;
}

.current_plan_card,
.plan_usage_wrapper {
  height: calc(100% - 28px) !important;
}

.invoice_preview .p-dialog-content {
  background-color: transparent !important;
}

.invoice_preview .invoice_wrapper {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.invoice_preview {
  box-shadow: none !important;
}

.bg-white-opacity {
  background-color: #ffffff80;
}

.radio_bg.p-radiobutton .p-radiobutton-box {
  width: 12px;
  height: 12px;
}

.radio_bg.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 0;
  height: 0;
}

.arrow_container .cursor-pointer:hover {
  color: #4f52ff !important;
}

#number_of_screens {
  font-size: 26px;
  font-weight: bolder;
  padding: 0 14px !important;
  color: #4f52ff;
}

.discount_label {}

.discount_label {
  border-top: 41px solid #696CFF;
  border-bottom: 41px solid transparent;
  border-right: 40px solid transparent;
  border-radius: 0.375rem 0 0 0;
  transform: rotate(90deg);
  position: absolute;
  text-align: center;
  right: 21px;
  top: -22px;
}

.discount {
  position: absolute;
  transform: rotate(45deg);
  font-size: 12px;
  color: white;
  right: 3px;
  top: 3px;
  font-weight: bold
}