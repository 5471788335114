@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

.landingBody {
    background-color: white;
}

nav {
    background-color: rgba(73, 97, 173, 0.9);
}

.navbar .navbar-brand {
    display: flex;
    align-items: center;
}

.navbar .navbar-brand img {
    margin-right: 5px;
}



.navbar .navbar-nav .nav-list {
    margin-right: 10px;
}

.navbar .navbar-nav .nav-list a {
    color: #fff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.navbar .navbar-nav .nav-item button {
    margin-top: 10px;
}

.navbar .navbar-nav .nav-item:first-child button {
    margin-right: 10px;
}

.login-button {
    color: #fff;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
}

.start-button {
    background-color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #012340;
    border-radius: 8px;
}

.welcome-button {
    background: white;
    color: black;
    padding: 10px;
    border-radius: 15px;
}

.nav-tabs {
    border: none;
}

.navbar-nav .nav-link:hover {
    color: white !important;
}

.navbar-nav .nav-item .login-button:hover {
    color: white !important;
}

/*             end nav style                    */

.sec1 {
    background-image: url('../../img/Landing/section1.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    min-height: 107vh;
}

.sec1-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.28);
    margin-bottom: 53px;
    margin-top: 42px;
}

.sec1-paragraph {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
}

.sec1-button {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
    margin-top: 36px;
    padding: 15px 40px 15px 40px;
    border: none;
    transition: all 0.5s ease;
    transform: translateY(0px);
}

.sec1-button:hover {
    color: #fff;
    background-color: #0EB2D1;
    border: none;
    transform: translateY(-5px);

    /* padding: 15px 43px 15px 43px; */
    /* margin: 0 0 5px 0; */
}

.sec1-img {
    height: 100%;
    margin: 20px auto;
}

.sec1-content {
    padding-top: 210px;
}

.circle {
    z-index: 2;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.screen1_img {
    z-index: 1;
    top: -33px;
    right: 387px;
    animation: linear infinite;
    animation-name: screen1_img;
    animation-duration: 5s;

}

@keyframes screen1_img {
    0% {
        top: -13%;
    }

    50% {
        top: 0;
    }

    100% {
        top: -13%;
    }
}

.screen2_img {
    z-index: 3;
    top: 230px;
    right: 75px;
    animation: linear infinite;
    animation-name: screen2_img;
    animation-duration: 5s;
}

@keyframes screen2_img {
    0% {
        right: 0;
    }

    50% {
        right: 50px;
    }

    100% {
        right: 0;
    }
}

.screen3_img {
    z-index: 4;
    top: 19px;
    right: 53px;
    animation: linear infinite;
    animation-name: screen3_img;
    animation-duration: 5s;
}

@keyframes screen3_img {
    0% {
        right: 0;
    }

    50% {
        right: 45px;
    }

    100% {
        right: 0;
    }
}

/*------------------------------- 
---------end section 1-----------
---------------------------------*/

.sec2 {
    margin-top: 7rem;
    margin-bottom: 9rem;
}

.sec2-span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #4961AD;
}

.sec2-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #2A2B2D;
}

.sec2-paragraph {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2A2B2D;
}

.sec2-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sec2-content img {
    margin-right: 10px;
}

.sec2-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #2A2B2D;
}

/*             end section 2                   */

.sec3 {
    display: flex;
    align-items: center;
}

.sec3-content h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #2A2B2D;
    margin-bottom: 41px;
}

.sec3-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2A2B2D;
    width: 24rem;
}

/*-------------------------------------------------
------------------end section 3--------------------
---------------------------------------------------*/
.bg_sec4 {
    z-index: 0;
    top: -160px;
    right: 100px;
}

.network_gif {
    z-index: 3;
    top: -360px;
}

.first_card {
    background-image: url("../../../assets/img/Landing/se8-1.jpg");
    background-position: left top;
    background-size: cover;
}

.second_card {
    background-image: url("../../../assets/img/Landing/sec8-2.jpg");
    background-position: left top;
    background-size: cover;
}

.third_card {
    background-image: url("../../../assets/img/Landing/sec8-3.jpg");
    background-position: left center;
    background-size: cover;
}

.fourth_card {
    background-image: url("../../../assets/img/Landing/sec8-4.jpg");
    background-position: left center;
    background-size: cover;
}

.sec4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 9rem;
}

.sec4-title h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2A2B2D;
}

.sec4-title p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2A2B2D;
    margin-top: 32px;
    width: 25rem;
}

.card-design {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px 0 20px 0;
    position: unset;
    border: 2px solid white;
    transition: all .5s;
}

.card-design:hover {
    border: 2px solid #4961AD;
}

.sec4-card {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sec4-card-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #2A2B2D;
    width: 9rem;
}

/*             end section 4                   */

.sec5 {
    margin-top: 8rem;
}

.sec5-head h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #2A2B2D;
}

.sec5-head p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #2A2B2D;
}

.sec5-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 10px 0 20px 0;
    position: unset;
    height: 90%;
    border: 2px solid white;
    transition: all .5s;
}

.sec5-card:hover {
    border: 2px solid #4961AD;
}

.sec5-card-content h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #2A2B2D;
}

.sec5-card-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #2A2B2D;
    width: 12rem;
    text-align: left;
}

.sec5-card-image img {

    margin-top: 5px;
}

.sec5-align {
    display: flex;
    align-items: center;
}

/*             end section 6                   */

.sec6-margin {
    margin-top: 7rem
}

.sec6 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2A2B2D;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sec6 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2A2B2D;
}

.sec6-content p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #2A2B2D;
}

.sec6-content p span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #2A2B2D;
}

/*             end section 7                   */

.sec7 {
    margin-top: 7rem;
}

.sec8 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
}

.sec8 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2A2B2D;
}

.sec2 p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2A2B2D;
}

.card-sec8 {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
    transform: translateX(-50%) translateY(-50%) translateZ(0);
    width: 296px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
    border-radius: 23px;
}

.card-sec8 a {
    color: inherit;
    text-decoration: none;
}

.card-sec8:hover {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}

.card-sec8:hover .card__thumb {
    background-color: rgba(0, 0, 0, 0.615) !important;
}

/**
* THUMB
**/

.card__thumb {
    height: 245px;
    overflow: hidden;
    background-color: #000;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.card__thumb img {
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.card-sec8:hover .card__thumb {
    height: 130px;
}

.card-sec8:hover .card__thumb img {
    opacity: 0.6;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}


/**
* card_BODY
**/

.card__body {
    position: relative;
    height: 90px;
    padding: 20px;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.card-sec8:hover .card__body {
    height: 215px;
}

.card__title {
    margin: 0;
    padding: 0 0 10px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #37474F;
}

.card__description {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 23px;
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #37474F;
    opacity: 0;
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.desc1 {
    bottom: 60px;
}

.desc2 {
    bottom: 44px;
}

.card-sec8:hover .card__description {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

/*--------------------------------   
----------end section 8-----------
----------------------------------*/

.sec9-margin {
    margin-top: 6rem;
    background-color: #F8F9FA !important;
}

.sec9 span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
}

.sec9 h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #2A2B2D;
}

.switch-wrapper {
    position: relative;
    display: inline-flex;
    padding: 4px;
    border: 1px solid lightgrey;
    border-radius: 30px;
    background: white;
    width: 11rem;
}

.switch-wrapper [type="radio"] {
    position: absolute;
    left: -9999px;
}

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"],
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"] {
    color: white;
}

.switch-wrapper [type="radio"]:checked#monthly~label[for="monthly"]:hover,
.switch-wrapper [type="radio"]:checked#yearly~label[for="yearly"]:hover {
    background: transparent;
}

.switch-wrapper [type="radio"]:checked#monthly+label[for="yearly"]~.highlighter {
    transform: none;
}

.switch-wrapper [type="radio"]:checked#yearly+label[for="monthly"]~.highlighter {
    transform: translateX(100%);
}

.switch-wrapper label {
    font-size: 16px;
    z-index: 1;
    width: 100%;
    line-height: 32px;
    cursor: pointer;
    border-radius: 30px;
    transition: color 0.25s ease-in-out;
    text-align: center;
}

.switch-wrapper label:hover {
    background: whitesmoke;
}

.switch-wrapper .highlighter {
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    border-radius: 30px;
    background: #4961AD;
    transition: transform 0.25s ease-in-out;
}

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

#pricing .container {
    background-color: #F8F9FA;
}

.table-wrapper {
    background: white;
    overflow-x: auto;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

}

.table-wrapper-head tr th:nth-child(1) {
    border: none;
    background-color: #f8f9fa;
}

.table-wrapper th:nth-child(3),
.table-wrapper td:nth-child(3) {
    border-right: 5px solid #0EB2D1;
    border-left: 5px solid #0EB2D1;
}

.pro {
    border-top: 5px solid #0EB2D1;
}

.label-pro {
    position: absolute;
    top: 0;
    right: -8px;
    background: linear-gradient(116.04deg, #0EB2D1 -44.01%, #4961AD 25.71%, #3980BB 104.73%);
    width: 77px;
    color: #fff;
    font-size: 0.8em;
    border-radius: 3px;
    transform: rotate(43.07deg);
    margin-top: 17px;
    height: 26px;
}

.pro-footer {
    border-bottom: 5px solid #0EB2D1;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.table-button td:nth-child(1) {
    border: none;
    background-color: #f8f9fa;
}

.table-wrapper-head {
    background: #DAE3FF;
    border-radius: 15px 15px 0px 0px;
}

.table-wrapper-body {
    background: linear-gradient(180deg, #E3EAFF 0%, #CFECFB 100%);
    /* border-radius: 15px 0px 0px 15px; */
    border-radius: 15px !important;
}

.table-wrapper-body tr .first-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2A2B2D;
    text-align: justify;
}

.info {
    text-align: center;
    margin-top: 7px;
}


.table-wrapper-body tr td {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2A2B2D;
    text-align: center;
}

.table-wrapper tr {
    display: flex;
}

.table-wrapper tr:not(:last-child) {
    border-bottom: 1px solid #ADADAD;
}

.table-button td button {
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 8px;
    width: 150px;
    height: 30px;
}

.table-button td button:hover {
    background: #0EB2D1;
    border-radius: 8px;
    color: white;
}

.table-wrapper th,
.table-wrapper td {
    width: 25%;
    min-width: 150px;
}

.table-wrapper th:nth-child(1) {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 1.3;
    padding: 1rem 10px;
}

.table-wrapper th:nth-child(1) .svg-wrapper {
    margin-top: 10px;

}

.table-wrapper th:nth-child(1) svg {
    width: 22px;
    height: 22px;
}

.table-wrapper th .heading {
    text-align: center;
    margin-top: 5px;
}

.table-wrapper th:nth-child(2) .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
}

.table-wrapper th:nth-child(3) .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
}

.table-wrapper th:nth-child(4) .heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #4961AD;
}

.table-wrapper th .popular {
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 11px;
    background: #ffdd40;
    padding: 4px 8px;
    border-radius: 2px;
}

.table-wrapper th .amount {
    font-size: 2rem;
}

.table-wrapper th .amount span {
    display: block;
    transform: translateY(-8px);
}

.table-wrapper th:nth-child(2) .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #012340;
}

.table-wrapper th:nth-child(3) .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #012340;
}

.table-wrapper th:nth-child(4) .amount {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #012340;
}

.table-wrapper th .billing-msg,
.table-wrapper th .amount span {
    font-weight: normal;
    font-size: 0.8rem;
}

.table-wrapper .hide {
    display: none;
}

.table-wrapper-body tr td:not(:first-child) {
    display: flex !important;
    justify-content: center !important;
}

.bg_ghost_white {
    background-color: #E2EAFE;
}

.border_top_left {
    -webkit-border-top-left-radius: 15px !important;
    -moz-border-radius-topleft: 15px !important;
    border-top-left-radius: 15px !important;

}

.td_top_shadow {
    box-shadow: 0px -1px 10px 0px rgba(156, 152, 152, 0.50);
    -webkit-box-shadow: 0px -1px 10px 0px rgba(156, 152, 152, 0.50);
    -moz-box-shadow: 0px -1px 10px 0px rgba(156, 152, 152, 0.50);
}

.head_row {
    background-color: #F8F9FA;
}

thead tr {
    background-color: #E2EAFE;
}

.tbody {
    border-radius: 15px !important;
}

@media screen and (min-width: 780px) {
    table td {
        padding: 10px 20px;
    }
}

@media (max-width: 767px) {
    .switch-wrapper {
        padding: 0;
        width: 6rem;
    }

    .switch-wrapper label {
        font-size: 11px;
    }


    .table-wrapper {
        overflow-x: scroll;
        width: 434px;
    }



    .table-wrapper th,
    .table-wrapper td {
        width: 50%;
        min-width: 94px;
    }

    .table-wrapper th:nth-child(2) .heading {
        font-size: 14px;
        line-height: 22px;
    }

    .table-wrapper th:nth-child(2) .amount {
        font-size: 21px;
        line-height: 41px;
    }

    .table-wrapper th:nth-child(3) .heading {
        font-size: 14px;
        line-height: 22px;
    }

    .table-wrapper th:nth-child(3) .amount {
        font-size: 21px;
        line-height: 37px;
    }

    .table-wrapper th:nth-child(4) .heading {
        font-size: 12px;
        line-height: 25px;
    }

    .table-wrapper th:nth-child(4) .amount {
        font-size: 21px;
        line-height: 37px;
    }

    .table-wrapper th:nth-child(1) {
        padding: 0;
    }

    .first-text {
        font-weight: 600;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
    }

    .table-button td button {
        width: 72px;
        height: 30px;
        font-size: 10px;
    }

    .label-pro {
        top: 72px;
        right: 13px;
        transform: rotate(0.07deg);
        margin-top: 19px;
        height: 21px;
    }

    .footer-card {
        width: 100%;
    }
}

/*--------------------------------
----------end section 9-----------
---------------------------------- */

.icon_phone {
    width: 30px;
    height: 35px;
}

.icon_envelop {
    width: 40px;
    height: 30px;
}

.icon_location {
    width: 30px;
    height: 35px;
}

.landing-footer {
    background-image: url('../../img/Landing/footer.png');
    background-size: cover;
}

.landing-footer-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-footer-text h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #FFFFFF;
}

.landing-footer-text p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.footer-card {
    background: #FFFFFF;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 55%;
}

.footer-button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #0EB2D1;
    border-radius: 8px;
    color: #FFFFFF;
    Width: 150px;
    Height: 40px;
    border: NONE;
}

.footer-form {
    background: #F9F9F9;
    border: 1px solid #C0C0C0;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.footer-icon-width {
    width: 59%;
}

.footer-form-group textarea {
    padding: 15px;
}

/*             end footer                   */